<template>
  <div class="preview-box">
    <div class="preview-box-inner">
      <v-icon
        class="no-print print-preview"
        @click="print()"
      >mdi-printer</v-icon>
      <v-icon
        class="no-print close-preview"
        @click="goBack()"
      >mdi-close-circle</v-icon>
      <div class="preview-inner">
        <v-row dense class="mt-5">
          <v-col class="col-7 pt-0">
            <p style="min-height:100px" class="text-mid text-heavy bordered pa-1">
              {{ customer.name }}<br />
              <span v-if="customer.address_1">
                <span v-if="customer.address_1">{{ customer.address_1 }}, <br /></span>
                <span v-if="customer.address_2">{{ customer.address_2 }}, <br /></span>
                <span v-if="customer.address_3">{{ customer.address_3 }}, <br /></span>
                <span v-if="customer.address_4">{{ customer.address_4 }}, <br /></span>
                <span v-if="customer.address_5">{{ customer.address_5 }}, <br /></span>
                <span v-if="customer.postcode">{{ customer.postcode }}, <br /></span>
              </span>
            </p>
          </v-col>
          <v-col class="col-1 pt-0"></v-col>
          <v-col class="col-4 pt-0">
            <img width="180px" src="../../assets/logo.png" alt="" />
            <p class="text-small mb-0">
              Riggott &amp; Co Limited<br />
              Unit X, Lodge Lane Industrial Estate<br />
              Tuxford, Newark, Nottinghamshire, NG22 0NL<br />
              Tel: (01777) 872525 Fax: (01777) 872626<br />
              <span class="text-tiny">
                Email: info@riggott.co.uk Web: www.riggott.co.uk
              </span>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12">
            <div style="min-height: 127mm">
              <v-row dense>
                <v-col class="col-2 bordered no-right">
                  <span class="text-mid text-heavy">
                    Invoice No
                  </span>
                </v-col>
                <v-col class="col-2 bordered no-right">
                  <span class="text-mid text-heavy">
                    Date
                  </span>
                </v-col>
                <v-col class="col-5 bordered no-right">
                  <span class="text-mid text-heavy">
                    Site
                  </span>
                </v-col>
                <v-col class="col-1 bordered no-right">
                  <span class="text-mid text-heavy">
                    Amount
                  </span>
                </v-col>
                <v-col class="col-1 bordered no-right">
                  <span class="text-mid text-heavy">
                    O/S
                  </span>
                </v-col>
                <v-col class="col-1 text-right bordered">
                  <span class="text-mid text-heavy">
                    Paid
                  </span>
                </v-col>
              </v-row>
              <v-row dense v-for="(line, index) in statement" :key="`l_${index}`">
                <v-col class="col-2 border-left text-tiny">
                    {{ line.number }}
                </v-col>
                <v-col class="col-2 border-left text-tiny">
                    {{ line.date | tinyDate }}
                </v-col>
                <v-col class="col-5 border-left text-tiny">
                    {{ line.siteAddress }}
                </v-col>
                <v-col class="col-1 border-left text-tiny">
                    {{ line.amount | priceInPounds | currency }}
                </v-col>
                <v-col class="col-1 border-left text-tiny">
                  {{ line.outstanding | priceInPounds | currency }}
                </v-col>
                <v-col class="col-1 text-right border-left border-right text-tiny">
                  {{ line.paid | priceInPounds | currency }}
                </v-col>
              </v-row>
              <v-row v-for="n in blankLines" :key="n" dense>
                <v-col class="col-2 pt-0 border-left">&nbsp;</v-col>
                <v-col class="col-2 pt-0 border-left">&nbsp;</v-col>
                <v-col class="col-5 pt-0 border-left">&nbsp;</v-col>
                <v-col class="col-1 pt-0 border-left">&nbsp;</v-col>
                <v-col class="col-1 pt-0 border-left">&nbsp;</v-col>
                <v-col class="col-1 pt-0 text-right border-left border-right">&nbsp;</v-col>
              </v-row>
              <v-row dense>
                <v-col class="border-top"></v-col>
              </v-row>
              <v-row dense class="pt-2">
          <v-col class="col-6">&nbsp;</v-col>
          <v-col class="col-6">
            <p style="min-height:40px" class="text-mid bordered pa-1">
              <span class="text-heavy">Total:</span>
              <span class="text-heavy float-right">{{ total | priceInPounds | currency }}</span>
            </p>
          </v-col>
        </v-row>
        <v-row dense class="pt-2">
          <v-col class="col-10 border-top pb-0">
            <p class="text-center text-mid mb-0">
              <span class="text-heavy">Payment terms:</span>
              Payment is due 30 days from date of invoice
              <span class="text-heavy">Bank:</span> NatWest<br />
              <span class="text-heavy">Sort Code:</span> 60-17-28
              <span class="text-heavy">a/c no:</span> 90117395
              &nbsp;&nbsp;&nbsp;We also accept payment by credit/debit card
            </p>
          </v-col>            
          <v-col class="col-2 border-top pb-0">
            <p class="text-center">
              <img width="200px" src="../../assets/payment_logos.png" alt="" />
            </p>
          </v-col>
        </v-row>
        <v-row class="mt-0" dense>
          <v-col class="col-12 pt-0">
            <p class="text-center text-mid">
              Company registered in England Number 2985947
              VAT Registration Number GB 598 6171 83    UTR 74932 96911<br />
              <img width="700px" src="../../assets/riggott_accreditation_logos.png" alt="" />
            </p>
          </v-col>
        </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'InvoicesStatement',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      customer: {},
      statement: [],
      blankLines: 5,
      total: 0,
    };
  },
  methods: {
    goBack() {
      this.$router.go('-1');
    },
    print() {
      window.print();
    },
    getCustomer() {
      const customerId = this.$route.params.id;
      axios.get(`/customers/getSingle/${customerId}.json?token=${this.token}`)
        .then((response) => {
          this.customer = response.data.customer;
          this.getStatement();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStatement() {
      const postData = {};
      postData.customerId = this.$route.params.id;
      postData.dates = [];
      if (this.$route.params.date1) {
        postData.dates[0] = this.$route.params.date1;
        postData.dates[1] = this.$route.params.date2;
      } else {
        postData.dates = [];
      }
      axios.post(`/invoices/getStatement.json?token=${this.token}`, postData)
        .then((response) => {
          this.statement = response.data.statement;
          this.total = response.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getCustomer();
  },
};
</script>

<style lang="scss" scoped>
@page {
  size: A4;
  margin: 0;
}
@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
}
.text-tiny {
  font-size: 10px;
}
</style>
